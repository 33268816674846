import React from 'react'

export const Pricetag = (props) => {
  return (
    <div id='pricetag'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title'>
          <h2 className='text-center'>Pricing</h2>
        </div>
        <div className='box'>
          <div className="row">
            
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Repair Service</h5>
                  <ul>
                    <li><img src="./img/pricetag/acrepair.webp" className="lazy" alt="ac repair" />
                      <div className="title_service">Repair Service</div>
                      <div className="service_details_">
                        <ul>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Checks the elements properly</li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Checks all the wears and tears of the wires</li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Checks the performance of the air conditioner</li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Troubleshoots the problem in an efficient manner</li>
                        </ul>
                      </div>
                      <div className="book_now_starting">
                        <div className="rate_price pr">
                          <span>Rs. 499/-</span>
                        </div>
                        <div className="tsapply" style={{color:'red'}}>
                          <div className="" >*T&amp;C Apply <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span className="">

                              <p><strong>Note:</strong> Price may vary according to Distance, Brand, Model &amp; Condition of Products </p>

                            </span>
                          </div>
                        </div>
                        <a href='/#contact' className='btn btn-pricetag page-scroll' >
                          Book Now
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Routine Service</h5>
                  <ul>
                    <li><img src="./img/pricetag/windowacinstall.webp" className="lazy" alt="installation" />
                      <div className="title_service">Installation</div>
                      <div className="service_details_">
                        <ul>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Include Fixing of AC stand in Windows </li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Connect inlet AC assembly to Air Conditioner </li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Connect electricity supply to Air Conditioner </li>
                          <li><i className="fa fa-check-circle" aria-hidden="true"></i> Check Window AC functions after installation </li>

                        </ul>
                      </div>
                      <div className="book_now_starting">
                        <div className="rate_price pr">
                          <span>Rs. 599/-</span>
                        </div>
                        <div className="tsapply"style={{color:'red'}}>
                          <div className="">*T&amp;C Apply <i className="fa fa-exclamation-circle" aria-hidden="true"></i>
                            <span className="">

                              <p><strong>Note:</strong> Price may vary according to Distance, Brand, Model &amp; Condition of Products </p>

                            </span>
                          </div>
                        </div>
                        <a href='/#contact' className='btn btn-pricetag page-scroll' >
                          Book Now
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}