import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';


export const Navigation = (props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLinkClick = () => {
    setIsMenuOpen(false);
  };
  return (
    <>
      <nav id='menu' className='navbar navbar-default navbar-fixed-top'>
        <div className='container'>
          <div className='navbar-header'>
            <button
              type='button'
              className='navbar-toggle collapsed'
              data-toggle='collapse'
              data-target='#bs-example-navbar-collapse-1'
              onClick={handleMenuClick}
            >
              {' '}
              <span className='sr-only'>Toggle navigation</span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
              <span className='icon-bar'></span>{' '}
            </button>
            <div style={{ display: 'inline-block' }}>
              <a className='navbar-brand page-scroll' href='/'>
                <img style={{ height: 30, overflow: 'hidden' }} align="left" src="img/foslogonlogobg2.png" alt='logo' />
                Brokberry
              </a>{' '}
            </div>
          </div>

          <div
            className={`collapse navbar-collapse ${isMenuOpen ? 'show' : ''}`}
            id='bs-example-navbar-collapse-1'
          >
            <ul className='nav navbar-nav navbar-right'>
              <li>
                <a href='#about' className='page-scroll' onClick={handleLinkClick}>
                  About
                </a>
              </li>
              {/*<li>
                <a href='#blog' className='page-scroll' onClick={handleLinkClick}>
                  Blog
                </a>
              </li>*/}
              <li>
                <a href='#features' className='page-scroll' onClick={handleLinkClick}>
                  What We Do
                </a>
              </li>

              <li>
                <a href='#services' className='page-scroll' onClick={handleLinkClick}>
                  Services
                </a>
              </li>
              <li>
                <a href='#pricetag' className='page-scroll'>
                  Pricing
                </a>
              </li>
              <li>
                <a href='#testimonials' className='page-scroll' onClick={handleLinkClick}>
                  Testimonials
                </a>
              </li>
              <li>
                <a href='#contact' className='page-scroll' onClick={handleLinkClick}>
                  Contact
                </a>
              </li>
              <li>
                <a className="" href="tel:9031062012" style={{ color: '#ef630b' }}>Call Us: 9031062012</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}
