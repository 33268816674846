import React, { useState } from 'react'

const Footerdetail = (props) => {
  return (
    <>
      <div id='footerdetail'>
        <div id='callus'>
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <h3>Do you need your dream house?</h3>
                <br />
                <a className="btn-right" href="#contact">Book A Free Assessment</a>

              </div>

              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12 contact-info">
                <div className='contact-item'>
                  <h3>Contact Info</h3>
                  {/*
                                <p>
                                    <span>
                                        <i className='fa fa-map-marker'></i> Address:
                                    </span>
                                    {props.data ? props.data.address : 'loading'}
                                </p>
                              */}
                </div>
                <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-phone'></i> Phone:
                    </span>{' '}
                    <a href="tel: 9031062012">
                      {props.data ? props.data.phone : 'loading'}</a>
                  </p>
                </div>
                <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-envelope-o'></i> Email:
                    </span>{' '}
                    <a href="mailto: contactbrokberry@gmail.com">
                      {props.data ? props.data.email : 'loading'}</a>
                  </p>
                </div>
                <div className='contact-item'>
                  <p>
                    <span>
                      <i className='fa fa-whatsapp'></i> Whatsapp:
                    </span>{' '}
                    <a href="https://api.whatsapp.com/send?phone=919031062012" target="_blank">
                      {props.data ? props.data.phone : 'loading'}</a>
                  </p>
                </div>

              </div>


              <div className="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <ul className='contact-item'>
                  <h3>UseFul Links</h3>
                  <li>
                <a href='#about' className='page-scroll'>
                  About
                </a>
              </li>
              {/*<li>
                <a href='/' className='page-scroll'>
                  Blog
                </a>
                            </li>*/}
              <li>
                <a href='#features' className='page-scroll'>
                  What We Do
                </a>
              </li>

              <li>
                <a href='#services' className='page-scroll'>
                  Services
                </a>
              </li>
              
                <li>
                  <a href='#pricetag' className='page-scroll'>
                    Pricing
                  </a>
                </li>
              

              <li>
                <a href='#testimonials' className='page-scroll'>
                  Testimonials
                </a>
              </li>
              <li>
                <a href='#contact' className='page-scroll'>
                  Contact
                </a>
              </li>
              
                </ul>

              </div>
            </div>


          </div>
        </div>

      </div>
    </>
  )
}

export default Footerdetail;