import React from 'react'

var d = new Date();
var currYear = d.getFullYear();
export const Footer = (props) => {
  
  return (
    <div id='footer'>
    <div className='container text-center'>
    <div className="about-social-icon text-center">
    

    <ul className="about-social">
        <li className="wow fadeIn" data-wow-delay=".2s">
          <a
            href="https://www.facebook.com"
            target="newtab"
          >
            <i className="fa fa-facebook" aria-hidden="true"></i>
          </a>
        </li>
        <li className="wow fadeIn" data-wow-delay=".4s">
          <a
            href="https://www.instagram.com/"
            target="newtab"
          >
            <i className="fa fa-instagram" aria-hidden="true"></i>
          </a>
        </li>
        <li className="wow fadeIn" data-wow-delay=".6s">
          <a href="https://www.linkedin.com/in/" target="newtab">
            <i className="fa fa-linkedin" aria-hidden="true"></i>
          </a>
        </li>
        <li className="wow fadeIn" data-wow-delay=".8s">
          <a href="https://twitter.com/" target="newtab">
            <i className="fa fa-twitter" aria-hidden="true"></i>
          </a>
        </li>
        <li className="wow fadeIn" data-wow-delay="1s">
          <a href="https://pin.it/" target="newtab">
            <i className="fa fa-pinterest" aria-hidden="true"></i>
          </a>
        </li>
        <li className="wow fadeIn" data-wow-delay="1.2s">
          <a href="https://www.quora.com/profile/" target="newtab">
            <i className="fa fa-quora" aria-hidden="true"></i>
          </a>
        </li>
      </ul>
    </div>
      <p>
      Copyright  {currYear}. All rights reserved &copy; Brokberry {' '}
      </p>
      
    </div>
      </div>
  )
}

