import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";
import { Header } from "./components/header";
import { Features } from "./components/features";
import { About } from "./components/about";
import { Services } from "./components/services";
import { Pricetag } from "./components/pricetag/pricetag";
import { Branch } from "./components/branchnav/Branch";

import { Testimonials } from "./components/testimonials";
import { Team } from "./components/Team";
import { Contact } from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import CarouselSlider from "./components/CarouselSlider";
import { Footer } from "./components/footer";

import Crous from "./components/crous";
import Caurousel from "./components/Caurousel";


//import MediaCard from "./components/future/mediacard"

import { green } from '@mui/material/colors';
import Crous2 from "./components/Crous2";
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Chooseus from "./components/chooseus";

import Footerdetail from "./components/Footerdetail";

import Certifications from "./components/Certifications";
import { Products } from "./components/products/Products"
import { Blog } from "./components/blog/blog";
import SocialBlog from "./components/socialblog/socialblog";
import { Faq } from "./components/faq";
import { Detailtext } from "./components/detalitext";
import { Choosetext } from "./components/choosetext";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

{/*
const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};
 const fabGreenStyle = {
    color: 'common.white',
    bgcolor: green[500],
    '&:hover': {
      bgcolor: green[600],
    },
  };

*/}


const Home = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
      setLandingPageData(JsonData);
    }, []);
    
  
    return (
      <div>
      
        <Header data={landingPageData.Header} />
        
        <About data={landingPageData.About} />
        
        <Features data={landingPageData.Features} />
        <Choosetext />
        <Services data={landingPageData.Services} />
        <Products data={landingPageData.Products}/>
        <Detailtext/>
        <Certifications />
        <Pricetag data={landingPageData.Pricetag} />
        
        
        <Contact data={landingPageData.Contact} />
        <Faq />
        <Testimonials data={landingPageData.Testimonials} />
        
        
        {/*<Crous /> 
        <Blog data={landingPageData.Blog} />
        <Crous2 /> 
        <Caurousel />
        <Branch data={landingPageData.Branches} />
        
       <SocialBlog data={landingPageData.Features} />
        for whatspp web use link href="https://wa.me/9060591201"
        <Team data={landingPageData.Team} />
        
        
        <Chooseus />
      */}
      </div>
    );
  
}


export default Home;
