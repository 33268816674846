import { useState, useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import "./App.css";
import { Navigation } from "./components/navigation";
import Home from "./Home";
import Footerdetail from "./components/Footerdetail";
import { Footer } from "./components/footer";
import { Helmet } from "react-helmet";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});


const App = () => {

  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Helmet>
        <title>AC Repair Service @9031062012 | AC Repair Service</title>
        <meta name="keywords" content="ro service, water purifier service, ro repair, ro service near me, water purifier service near me, ro service center, ro service center near me, ro service centre, ro service centre near me" />
        <meta name="author" content="h2oro service is best ro repair and maintanancce service in india" />
        <meta name="description" content="RO Service Chatra: We are India's leading RO water purifier service provider of all brands like Kent, Pureit, Livpure, Aqua Guard, Aqua Fresh etc. RO repair service in Chatra."></meta>
      </Helmet>
      <div className='social'>
        <div>
          <a className="whatsapp" href="https://api.whatsapp.com/send?phone=919031062012" target="_blank">
            <i className="fa fa-whatsapp"></i>
          </a>
        </div>
        <div>
          <a className="phone" href="tel:9031062012">
            <i className="fa fa-phone"></i>
          </a>
        </div>
      </div>
      <Navigation />
      <Routes>
        <Route exact path="/" element={<Home />} />

      </Routes>
      <Footerdetail data={landingPageData.Contact} />
      
      <Footer data={landingPageData.Footer} />
    </div>

  );
};


export default App;

